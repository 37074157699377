//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../../cssInJs'

export default {
    pageTiles: css({
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        margin: '2rem -10px',
        overflow: 'hidden'
    }),
    pageTile__tile: css(mq({
        flex: '0 0 100%',
        maxWidth: '100%',
        padding: '10px',
        zIndex: 1,
        '&:nth-of-type(even)': {
            '.pageTile__bsyc__bg': mq({
                order: ['0', null, '2'],
            }),
            '.pageTile__bsyc__content__details': mq({
                order: '1'
            })
        }
    })),

    tile__content: css({
        //     display: 'flex',
        //     flexDirection: 'row',
        //     justifyContent: 'space-between',
        //     width: '100%',
        //     height: '100%',
        //     cursor: 'pointer',
        //     position: 'relative',
        //     flexWrap: 'wrap',
        '&:hover': {
            textDecoration: 'none',
            '& .pageTile__bsyc__content__details': {
                //color: 'white',
                backgroundColor: variables.charcoalGrey,
                // '&.default' : css({
                //      backgroundColor: 'rgba(0,113,188,1)'
                // }),
                // '&.red' : css({
                //      backgroundColor: 'rgba(211,61,2,1)'
                // }),
                // '&.green' : css({
                //      backgroundColor: 'rgba(0, 146, 69,1)'
                // })
                '& .pageTile__bsyc__title': {
                    color: 'white'
                },
                '& .pageTile__bsyc__desc': {
                    color: 'white'
                }
            },
            '& .pageTile__bsyc__bg2': {
                //transform: scale(1.05)
                transform: 'scale(1.05)'
            }
        }
    }),

    tile__flip__wrapper: css({
        zIndex: '-1',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        flexWrap: 'wrap'
    }),

    //     tile__bg : css(mq({
    //          height: '0',
    //          //paddingTop: '30%',
    //          paddingTop: ['50%','35%','30%'],
    //          backgroundPosition: '50%',
    //          backgroundRepeat: 'no-repeat',
    //          backgroundSize: 'cover',
    //          flex: '1 1 300px'
    //     })),

    tile__bg: css(mq({
        height: '0',
        paddingTop: ['50%', '35%', '30%'],
        //height: ['50%','35%','30%'],
        flex: '1 1 300px',
        overflow: 'hidden',
        position: 'relative',
        boxShadow: '3px 5px 10px rgba(0,0,0,0.3)'
    })),

    tile__bg2: css(mq({
        height: '100%',
        backgroundPosition: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        //overflow:'hidden',
        transition: 'all .2s',
        position: 'absolute',
        top: '0px',
        width: '100%'
    })),

    tile__details: css({
        overflow: 'hidden',
        flexGrow: '1',
        textAlign: 'center',
        width: '100%',
        bottom: '0',
        backgroundColor: variables.softGreen,
        //     '&.default' : css({
        //           backgroundColor: 'rgba(0, 113, 188,.5)'
        //     }),
        //     '&.red' : css({
        //           backgroundColor: 'rgba(211, 61, 2,.5)'
        //      }),
        //      '&.green' : css({
        //           backgroundColor: 'rgba(0, 146, 69,.5)'
        //      }),
        //backgroundColor : '#77cf22',
        flex: '1 1 300px',
        transition: 'all .2s ease-in-out',
        color: '#ebebeb',
        position: 'relative',
        boxShadow: '3px 5px 10px rgba(0,0,0,0.3)',
        '& .pageTile__bsyc__container': mq({
            //position: 'absolute',
            position: ['static,', null, 'absolute'],
            top: '50%',
            left: '50%',
            transform: ['none', null, 'translate(-50%, -50%)'],
            width: ['100%', null, '90%'],
            padding: ['1rem', null, '0px'],
            '& .pageTile__bsyc__title': {
                //fontFamily: 'proxima-soft, sans-serif',
                fontSize: '2rem',
                //textTransform: 'uppercase',
                //fontWeight: '700',
                lineHeight: '1',
                margin: '0',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                color: 'white',
                fontWeight: 700
            },
            '& .pageTile__bsyc__desc': {
                lineHeight: '1.3',
                fontSize: '.9rem',
                //color: '#3d3d3d',
                minHeight: '70px',
                color: 'white'
            }
        })
    })
}