import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/about', title: 'ABOUT ', icon: 'about.png?v=1', iconHover: 'about.png?v=1' },
    { url: '/services', title: 'SERVICES ', icon: 'services.png?v=1', iconHover: 'services.png?v=1' },
    { url: '/customers', title: 'CUSTOMERS ', icon: 'customers.png?v=1', iconHover: 'customers.png?v=1' },
    { url: '/careers', title: 'CAREERS ', icon: 'careers.png?v=1', iconHover: 'careers.png?v=1' },
    { url: '/education', title: 'EDUCATION ', icon: 'education.png?v=1', iconHover: 'education.png?v=1' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}
