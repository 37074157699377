const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}


const colors = {
  darkGreen : '#006e41',
  darkGrey : "#3b3b3b",
  yellow : '#f1c83c',
  softGreen : '#5E7C51',
  charcoalGrey: '#2F2D2D'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: '#c20f2f',
  fontColor: colors.darkGrey,
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;